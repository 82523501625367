import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '@hooks/useLanguage'
import { Page, PageContent } from '@layout/Page'
import { PageContainerWithHead } from '@layout/Page'
import { CommissionWizard } from './CommissionWizard'
import { CommisionContent } from '@pages/Commission/CommintionContent'
import styles from './CommisionPage.module.css'

export const CommissionPage: React.FC = () => {
  const { t } = useTranslation()
  const { routes } = useLanguage()
  const pageId = React.useId()

  const breadCrumbs = React.useMemo(
    () => [{ to: routes.Index, title: t('pages.main.title') }, { title: t('pages.commission.title') }],
    [t, routes]
  )

  window.location.reload()

  return (
    <Page id={pageId}>
      <PageContainerWithHead
        title={t('pages.commission.titleBig')}
        breadCrumbs={breadCrumbs}
        className={styles.commisionPage}
      >
        <PageContent>
          <CommisionContent />
          <CommissionWizard />
        </PageContent>
      </PageContainerWithHead>
    </Page>
  )
}
