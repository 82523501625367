import * as React from 'react'
import { Page } from '@layout/Page'

export const DamageAssessmentPage: React.FC = () => {
  const pageId = React.useId()

  window.location.reload()

  return <Page id={pageId}></Page>
}
